const checkForFiltersPosition = search => {
    if (window.innerWidth < '640') {
        search.addWidgets([
            instantsearch.widgets.refinementList({
                container: '#modal-filters',
                attribute: 'section',
                sortBy: ['name:asc'],
                templates: {
                    searchableNoResults() {
                        return 'No results';
                    },
                },
                cssClasses: {
                    count: ['ml-auto', 'py-1', 'px-2', 'text-white', 'bg-blue-900', 'rounded-md', 'text-sm'],
                    label: ['w-full', 'flex', 'items-center', 'mb-1'],
                    checkbox: 'mr-3',
                },
            })
        ])
    } else {
        search.addWidgets([
            instantsearch.widgets.refinementList({
                container: '#section-filters',
                attribute: 'section',
                sortBy: ['name:asc'],
                cssClasses: {
                    count: ['py-1', 'px-2', 'text-white', 'bg-blue-900', 'rounded-md', 'text-sm', 'ml-4'],
                    label: ['w-full', 'flex', 'items-center'],
                    checkbox: 'mr-3',
                    list: 'flex',
                    item: 'mr-6'
                },
            })
        ])
    }
}


document.addEventListener("DOMContentLoaded", function(){
    const searchClient = algoliasearch(process.env.MIX_ALGOLIA_APPLICATION_ID, process.env.MIX_ALGOLIA_SEARCH_API_KEY)

    const search = instantsearch({
        indexName: process.env.MIX_ALGOLIA_APPLICATION_NAME,
        searchClient,
        routing: true
    })

    const visibilityHandler = (adding) => {
        const filtersContainersDesk = document.querySelector('.no-results-hidden')
        const filtersContainersMobile = document.querySelector('.no-results-hidden-mobile')
        adding ? (window.innerWidth < '640' ? filtersContainersMobile.classList.remove('hidden') : filtersContainersDesk.classList.remove('sm:hidden')) : window.innerWidth < '640' ? filtersContainersMobile.classList.add('hidden') : filtersContainersDesk.classList.add('sm:hidden') 
    }

    const renderInfiniteHits = (renderOptions, isFirstRender) => {
        const { results, isLastPage } = renderOptions
        const container = document.querySelector('#hits')

        if (isFirstRender) {
            return;
        }

        container.style.display = results.query === '' ? 'none' : '';

        if (results.query.length === 0 ) {
            visibilityHandler(false)
            return;
        }

        if (results.hits.length > 0) {
            visibilityHandler(true)

            document.querySelector('#hits').innerHTML = `
                <ul>
                ${results.hits
                    .map(
                    item =>
                        `<li class="bg-white shadow-lg rounded-lg overflow-hidden w-full px-3 py-4 mt-3 transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-md hover:bg-blue-900 hover:text-white">
                        <a class="block w-full" href="${item.url}"><span class="px-2 py-1 inline-flex mb-2 cursor-pointer text-white border-b-2  border-transparent hover:border-white text-sm rounded bg-gray-200 text-blue-900">${item.section}</span><h3>${instantsearch.highlight({ attribute: 'title', hit: item })}</h3></a>
                        </li>`
                    )
                    .join('')}
                </ul>
                ${!isLastPage ?
                    `<button class="show-more mt-3 transition border-b-2 border-transparent hover:border-blue-900 hover:text-blue-900">
                        Show more entries
                    </button>` : ``
                }
            `;

            if (!isLastPage) {
                const showMore = document.querySelector('.show-more')
                showMore.addEventListener('click', () => {
                    renderOptions.showMore()
                })
            }

        } else {
            visibilityHandler(false)

            document.querySelector('#hits').innerHTML = `
                <p class="text-2xl mt-3">There are no results for <q>${results && results.query}</q>. <a role="button" class="show-more mt-3 transition border-b-2 border-transparent hover:border-blue-900 hover:text-blue-900" href=".">Clear search</a></p>
            `;
        }
    };

    const customInfiniteHits = instantsearch.connectors.connectInfiniteHits(renderInfiniteHits);

    search.addWidgets([
        instantsearch.widgets.searchBox({
            container: '#search-box',
            placeholder: 'Try typing "Argentina"',
            showReset: false,
            showSubmit: true,
            cssClasses: {
                input: ['text-md', 'md:text-xl', 'placeholder-gray-600', 'text-gray-800', 'pb-4', 'pt-5', 'pl-16', 'pr-4', 'rounded-lg', 'w-full', 'border-b-4', 'focus:outline-none', 'focus:border-blue-900'],
            }
        }),
        customInfiniteHits(),
        instantsearch.widgets.currentRefinements({
            container: '#current-refinements',
            cssClasses: {
                label: ['mr-4', 'mb-2'],
                categoryLabel: 'px-2',
                item: ['flex', 'flex-wrap', 'justify-start', 'items-center'],
                category: ['flex', 'bg-white', 'shadow-lg', 'rounded-lg', 'overflow-hidden', 'mr-4', 'mb-2', 'align-start', 'p-2', 'transition', 'duration-200', 'ease-in-out', 'transform', 'hover:-translate-y-1', 'hover:shadow-md',  'hover:bg-blue-900', 'hover:text-white']
            }
        }),
    ])

    checkForFiltersPosition(search)
    search.start()

    // Check for resizes on filters positions
    window.addEventListener('resize', () => {
        checkForFiltersPosition(search)
        search.refresh()
    })

    // Modal Filters Setup
    const toggleModal = () => {
        const body = document.querySelector('body')
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
        body.classList.toggle('modal-active')
    }

    const openModal = document.querySelector('#filtersModal')

    openModal.addEventListener('click', (ev) => {
        ev.preventDefault()
        toggleModal()
    })

    const overlay = document.querySelector('.modal-overlay')
    overlay.addEventListener('click', toggleModal)

    const closeModal = document.querySelectorAll('.modal-close')
    closeModal.forEach(node => {
        node.addEventListener('click', toggleModal)
    })

    document.onkeydown = function(evt) {
        evt = evt || Event
        let isEscape = false
        if ("key" in evt) {
            isEscape = (evt.key === "Escape" || evt.key === "Esc")
        } else {
            isEscape = (evt.keyCode === 27)
        }
        if (isEscape && document.body.classList.contains('modal-active')) {
            toggleModal()
        }
    };
})
